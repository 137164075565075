//breakpoint 0
@include media-breakpoint-up(xs) {

    .panel-accordion {
        padding: 0 34px 0;
        border: 1px solid rgba(0,0,0, .1);
        border-top: none;
        margin-bottom: 27px;
        text-align: justify;

        .header {
            padding-top: 8px;
            font-size: 16px;
            font-weight: 600;
            color: #2C2E30;
            margin-bottom: 37px;
        }

        ul {

            margin-bottom: 24px;

            li {
                margin-bottom: 0;
                line-height: 1.86;
            }

        }
    }

    .panel {
        /*! criticalCss */
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        text-align: justify;
        &--gated {
            max-height: none;
            .form-container {
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        @include mod('no-border') {
            border: none;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .accordion-accordion {
        width: 100%;
        text-align: left;
        border: 1px solid rgba(0,0,0, .1);
        border-bottom: none;
        background: none;
        font-weight: 600;
        font-size: 21px;
        line-height: 1.47;
        color: #2C2E30;
        padding: 18px 49px 18px 34px;
        outline: none;
        position: relative;
        transition: 0.3s;

        &:after {
            content: "\e921";
            font-family: 'icomoon';
            position: absolute;
            right: 15px;
            top: 35px;
            transform: translateY(-50%);
            font-size: 12px;
            color: #6FC0BC;
            transition: 0.3s;
        }

        &.active {

            &:after {
                transform: rotate(180deg) translateX(0) translateY(50%);
            }
        }

        &:active,
        &:focus {
            outline: none;
        }
    }

    .accordion {
        @include mod('no-border') {
            border: none;
            padding-left: 0;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}