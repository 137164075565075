//breakpoint 0
@include media-breakpoint-up(xs) {

    .scene {
        /*! criticalCss */
        display: none;
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .scene {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        display: block;
    }

    #scene {

        .icon {
            font-size: 30px;
            opacity: 1;
        }

        > div {

            position: relative !important; 

            &:first-child {

                .icon {
                    position: absolute;
                    top: 9vh;
                    left: 34.3%;
                    opacity: 0.5;
                }
            }

            &:nth-child(2) {

                .icon {
                    position: absolute;
                    top: 9vh;
                    left: 18.3%;
                    opacity: 0.5;
                }
            }

            &:nth-child(3) {

                .icon {
                    position: absolute;
                    top: 56vh;
                    left: 14.3%;
                    opacity: 0.5;
                }
            }

            &:nth-child(4) {

                .icon {
                    position: absolute;
                    top: 69vh;
                    left: 39.3%;
                    opacity: 0.1;
                }
            }

            &:nth-child(5) {

                .icon {
                    position: absolute;
                    top: 60vh;
                    left: 46.8%;
                    opacity: 0.8;
                }
            }

            &:nth-child(6) {

                .icon {
                    position: absolute;
                    top: 77vh;
                    left: 35.7%;
                }
            }
        }
    }

    #scene2 {

        .icon {
            font-size: 30px;
            opacity: 1;

            &:before {
                color: rgba(218,218,218, 1);
                
            }
        }

        > div {

            position: relative !important; 

            &:first-child {

                .icon {
                    position: absolute;
                    top: 21vh;
                    left: 77.3%;
                    font-size: 61px;
                    
                }
            }

            &:nth-child(2) {

                .icon {
                    position: absolute;
                    top: 38vh;
                    left: 16.3%;
                    font-size: 106px;
                    opacity: 0.2;
                }
            }

            &:nth-child(3) {

                .icon {
                    position: absolute;
                    top: 41vh;
                    left: 70.3%;
                    font-size: 71px;
                    opacity: 0.1;
                }
            }

            &:nth-child(4) {

                .icon {
                    position: absolute;
                    top: 61vh;
                    left: 26.5%;
                    font-size: 71px;
                    opacity: 0.1;
                }
            }

            &:nth-child(5) {

                .icon {
                    position: absolute;
                    top: 56vh;
                    left: 37.2%;
                    font-size: 48px;
                    opacity: 0.1;
                }
            }

            &:nth-child(6) {

                .icon {
                    position: absolute;
                    top: 62vh;
                    left: 78.4%
                }
            }
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}