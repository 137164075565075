//breakpoint 0
@include media-breakpoint-up(xs) {
    .styled-block {
        /*! criticalCss */
        position: relative;
        margin-left: -15px;
        padding-left: 65px;
        margin-bottom: 46px;

        &:before {
            /*! criticalCss */
            display: block;
            content: "";
            left: 0;
            width: 46px;
            top: 0;
            bottom: -10px;
            position: absolute;
            background: #6FC0BC;
            margin-right: 19px;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .styled-block {

        padding-left: 121px;
        margin-bottom: 76px;

        &:before {
            width: 94px;
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}