//breakpoint 0
@include media-breakpoint-up(xs) {
    .achievements-slider {
        /*! criticalCss */
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        margin-bottom: 45px;
        margin-left: -15px;
        margin-right: -15px;

        .item {
            /*! criticalCss */
            display: inline-block;
            margin-left: 15px;

            &:hover {
                text-decoration: none;
            }

            .logo-box {
                /*! criticalCss */
                height: 116px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                margin-bottom: 28px;
                justify-content: center;

                img {
                    /*! criticalCss */
                    max-height: 100%;
                }
            }

            .name-box {
                /*! criticalCss */
                text-align: center;

                @include el('bold') {
                    /*! criticalCss */
                    padding: 0 15px;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 1.53;
                    text-align: center;
                    letter-spacing: 0.196166px;
                    color: #272727;
                }

                @include el('regular') {
                    /*! criticalCss */
                    padding: 0 15px;
                    font-size: 15px;
                    line-height: 1.53;
                    text-align: center;
                    letter-spacing: 0.196166px;
                    color: #272727;
                }
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}