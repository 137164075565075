//breakpoint 0
@include media-breakpoint-up(xs) {
    .green-section {
        margin: 31px -15px 49px;
        padding: 58px 15px 1px;
        background: #ECF9FA;
        position: relative;

        &.margin-bottom-0 {
            margin-bottom: 0;
        }

        @include mod('cubes') {

            .icon {
                position: absolute;

                &:first-child {
                    font-size: 30px;
                    opacity: 0.2;
                    right: 28vw;
                    bottom: 8vw;
                }
                &:nth-child(2) {
                    font-size: 58px;
                    opacity: 0.4;
                    right: 10vw;
                    top: 10vw;
                }
                &:nth-child(3) {
                    font-size: 115px;
                    opacity: 0.2;
                    left: 100%;
                    bottom: 8vw;
                    transform: translateX(-65%);
                }
            }
        }
    }

    .slider {
        margin-right: -15px;

        .slick-slide {
            padding-right: 15px;
        }
    }

    .filos-slider {
        padding-bottom: 70px;
    }

    .white-icon-box {
        background: #fff;
        padding: 29px 15px 55px;
        text-align: center;

        .icon-box {
            position: relative;
            margin-bottom: 33px;

            .icon-cube-2 {
                font-size: 85px;
                color: #18202D;
            }

            .icon:first-child {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                font-size: 35px;
                color: #18202D;
            }

            .content {
                font-size: 15px;
                line-height: 1.86;
            }
        }
    }

    .about_us {
        /*! criticalCss */
        overflow: hidden;

        .image {
            margin: 0 -15px 0;
            width: calc(100% + 30px);
            max-width: none;
        }
    }

    .section {
        padding-top: 65px;
        
        .desktop {
            display: none;
        }

        .col-order {
            order: -1;
        }
    }

    .section-no-padding {
        padding-top: 0;
    }

    .team {
        padding-top: 18px;
    }

    .team-item {
        margin-bottom: 40px;

        .img-box {
            margin-bottom: 47px;
            width: 190px;
            height: 190px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .accordion {
            position: relative;
            cursor: pointer;

            &:after {
                font-size: 12px;
                content: "\e921";
                font-family: 'icomoon';
                color: #6fc0bc;
                position: absolute;
                right: 0;
                top: 0;
                color: #6fc0bc;
                transition: 0.3s;
            }

            &.active {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        @include el('name') {
            font-size: 21px;
            font-weight: 600;
            color: #000;
            line-height: 1.2;
        }

        @include el('position') {
            font-size: 16px;
            text-transform: uppercase;
            color: #6FC0BC;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .about_us {
        .image {
            margin: 0;
            width: calc(100% + 15px);
            max-width: calc(100% + 15px);
        }
    }

    .section-no-padding {
        padding-top: 65px;
    }

    .team {
        display: flex;
        flex-wrap: wrap;
        padding-top: 38px;

        .team-item {
            width: 50%;
            padding-right: 45px;
        }
    }

    .filos-slider {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -13px;

        .item {
            width: calc(50% - 26px);
            margin: 0 13px;
            margin-bottom: 26px;
        }
    }

    .section {
        .mobile {
            display: none;
        }

        .desktop {
            display: block;
            padding-top: 32px;
        }

        .col-order {
            order: 0;
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {
    .filos-slider {

        .item {
            width: calc(25% - 26px);
        }
    }

    .section {
        .col-12 {
            &:last-child {
                padding-left: 71px;
            }
        }
    }

    .green-section {

        h3 {
            margin-bottom: 61px;
        }

        @include mod('cubes') {

            h3 {
                font-size: 34px;
                margin-bottom: 61px;
            }

            .icon {

                &:first-child {
                    right: 36vw;
                    bottom: auto;
                    top: 3.7vw;
                }
                &:nth-child(2) {
                    right: 26vw;
                    bottom: 3.7vw;
                    top: auto;
                }
                &:nth-child(3) {
                    right: 10vw;
                    left: auto;
                    transform: none;
                    bottom: auto;
                    top: 2.9vw;
                }
            }
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .team {

        .team-item {
            width: 33.33%;
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}