//breakpoint 0
@include media-breakpoint-up(xs) {
    .benefit {
        /*! criticalCss */
        box-shadow: 0px 1.71032px 30px rgba(0, 0, 0, 0.1);
        margin-bottom: 23px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .inner {
            /*! criticalCss */
            padding: 0 42px;
            min-height: 260px;
            background: #FFFFFF;
            transition: 0.4s;
        }

        .intro {
            /*! criticalCss */
            width: 100%;
            position: relative;
            transition: 0.5s ease;
        }

        .extend {
            /*! criticalCss */
            width: 100%;
            text-align: center;
        }

        @include el('icon') {
            /*! criticalCss */
            position: relative;
            width: 100%;
            width: 75px;
            height: 85px;
            margin: 0 auto 37px;

            .icon {
                /*! criticalCss */
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                color: #18202D;

                &:first-child {
                    /*! criticalCss */
                    font-size: 33px;
                }

                &.icon-cube-2 {
                    /*! criticalCss */
                    font-size: 85px;
                }
            }
        }

        @include el('name') {
            /*! criticalCss */
            width: 100%;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.45;
            text-align: center;
            color: #18202D;
            margin-bottom: 24px;
            transition: 0.4s;
        }

        @include el('txt') {
            /*! criticalCss */
            width: 100%;
            font-size: 15px;
            line-height: 1.6;
            text-align: center;
            padding-bottom: 32px;
            color: #18202D;
            position: relative;
        }

        @include el('more') {
            /*! criticalCss */
            font-weight: 700;
            font-size: 1.22;
            line-height: 22px;
            color: #fff;
        }

        .extend {
            /*! criticalCss */
            opacity: 0;
        }

        &.active,
        &:hover {

            text-decoration: none;

            .inner {
                background: #141D33;
                padding: 53px 42px;
            }

            .benefit__name {
                color: #fff;

            }

            .intro {
                top: 0 !important;
            }

            .extend {
                animation: fadeIn 0.4s 0.35s forwards;
            }
            
            .benefit__txt {
                color: #fff;
                
            }

            .benefit__icon {

                .icon {
                    color: #fff;
                }
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {
    .benefit {
        /*! criticalCss */
        margin-left: 7px;
        margin-right: 7px;
        margin-bottom: 39px;

        .inner {
            /*! criticalCss */
            padding: 52px 42px;
        }
    }

    .benefits {
        .row {
            /*! criticalCss */
            padding-left: 34px;
            padding-right: 34px;
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}