//breakpoint 0
@include media-breakpoint-up(xs) {

    .page--landing {
        .link {
            color: #6fc0bc;
        }

        .image-size-fixed {
            max-width: 800px !important;
            width: 100%;
        }
    }
    .landing-row {

        margin-bottom: 46px;

        .landing-col {
            padding: 0;

            .image {
                overflow: hidden;

                img {
                    width: 152vw;
                    margin-bottom: 30px;
                    max-width: none;
                    transform: translateX(calc(-52vw - 30px));
                }
            }
        }
    }

    .form-container {
        /*! criticalCss */
        max-width: 914px;
        background: #FFFFFF;
        padding: 15px;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.13);
        margin-bottom: 52px;

        h4 {
            font-weight: 600;
            font-size: 20px;
            line-height: 1.84;
            text-align: center;
            color: #2C2E30;
            margin-bottom: 33px;
        }
    }

    .img-landing-typ {
        margin-bottom: 32px;
    }

    .landing-typ-container {
        padding-bottom: 36px;

        h3 {
            font-size: 20px;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {
    .landing-row {
        
        .landing-col {

            .image {

                position: relative;

                img {
                    display: block;
                    width: 60vw;
                    max-width: none;
                    margin: 0 auto 30px;
                    transform: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .page--landing {

        .image-size-fixed {
            margin: 0 auto;
            display: block;
        }
    }
}

//md: 768px
@include media-breakpoint-up(md) {
    .landing-row {

        margin-bottom: 100px;

        .landing-col-2 {
            padding-left: 6.9vw;
            padding-right: 11vw;
        }

    }

    .form-container {
        padding: 47px 83px 53px;
        margin-bottom: 82px;

        h4 {
            font-size: 25px;
            margin-bottom: 63px;
        }
    }

    .landing-typ-container {
        padding-bottom: 86px;

        h3 {
            font-size: 25px;
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {
    .landing-row {
        .landing-col {
            .image {

                position: relative;

                img {
                    width: 1150px;
                    margin-bottom: 30px;
                    max-width: none;
                    float: right;
                    transform: none;
                }
            }
        }
    }

    .page--landing {
        h3 {
            margin-bottom: 43px;
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .landing-row {

        .landing-col {
            
            .image {

                img {
                    width: 1000px;

                }
            }
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}