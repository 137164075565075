//breakpoint 0
@include media-breakpoint-up(xs) {
    .calendly-btn {
        display: none;
        opacity: 0;
        
        position: fixed;
        right: 15px;
        bottom: 15px;

        background: #141d33;
        z-index: 150;

        color: #fff;

        padding: 5px 15px;

        border-radius: 4px;
        border: solid 1px white;

        cursor: pointer;

        transition: all 0.2s;
        text-decoration: none;

        &:hover {
            color: #6fc0bc;
            text-decoration: none;
        }
    }

    #page-header {
        /*! criticalCss */
        padding-top: 14px;
        padding-bottom: 16px;
        position: fixed;
        top: 0;
        z-index: 10;
        left: 0;
        right: 0;
        background: #fff;

        &.page-header-shadow {
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
        }

        &.box-shadow {
            box-shadow: 0 0 10px rgba(0,0,0, .2);
        }

        .page-header-inner {
            /*! criticalCss */
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
                /*! criticalCss */
                position: relative;
                display: inline-block;

                img {
                    /*! criticalCss */
                    width: 100%;
                    max-width: 20.2vw;
                }

                .logo-note {
                    /*! criticalCss */
                    position: absolute;
                    left: 83%;
                    top: -17%;
                    font-size: 1rem;
                    background: rgba(70, 195, 207, 0.1);
                    color: #46C3CF;
                    line-height: 1;
                    padding: 4px 6px 4px 16px;
                    font-weight: 500;
                    transition: 0.3s;

                    &:before {
                        /*! criticalCss */
                        position: absolute;
                        display: block;
                        content: "";
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ADFF87;
                        left: 7px;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: 0.3s;
                    }

                    &:after {
                        /*! criticalCss */
                        position: absolute;
                        display: block;
                        content: "";
                        left: 6px;
                        top: 100%;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 6px 3px 0 3px;
                        border-color: #ecf9fa transparent transparent transparent;
                    }

                    &:hover {
                        text-decoration: none;
                        background: #46C3CF;
                        color: #fff;

                        &:after {
                            border-color: #46C3CF transparent transparent transparent;
                        }
                    }
                }
            }

            .btn-nav {
                /*! criticalCss */
                padding: 10px;
                display: flex;
                align-items: center;
                margin-right: -10px;

                .icon {
                    /*! criticalCss */
                    font-size: 20px;
                }
            }

            .btn-nav-2 {
                margin-left: auto;

                .btn-nav-label {
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 20px;
                }
            }

            .switch-lang {
                /*! criticalCss */
                display: flex;
                align-items: center;
                margin: 0 auto;

                .switch {
                    /*! criticalCss */
                    transform: scale(1.15);
                }

                @include mod('pillarpage') {
                    display: none;
                }
            }

            .side {
                /*! criticalCss */
                position: fixed;
                top: 0;
                left: 100%;
                width: 100%;
                overflow: auto;
                min-height: 100vh;
                display: flex;
                flex-wrap: wrap;
                padding-top: 70px;
                align-items: center;
                background: #fff;
                z-index: 999;
                justify-content: center;
                transition: 0.3s;
                padding-bottom: 30px;
                -webkit-overflow-scrolling: touch;
                bottom: 0;

                #close-nav {
                    /*! criticalCss */
                    position: absolute;
                    top: 25px;
                    right: 15px;
                    border: 1px solid #000;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                }

                &.menu-opened {
                    left: 0;
                }

                .main-nav {
                    /*! criticalCss */
                    overflow: auto;
                    padding-bottom: 70px;
                    width: calc(100% - 30px);

                    .menu {
                        /*! criticalCss */
                        width: 100%;
                        padding-left: 0;
                        padding-top: 30px;
                        list-style: none;

                        li {
                            /*! criticalCss */
                            text-align: center;

                            a {
                                /*! criticalCss */
                                color: #000;
                                font-weight: 600;
                                font-size: 18px;

                                &:hover {
                                    text-decoration: none;
                                    color: #6fc0bc;
                                }
                            }

                            &:last-child {
                                /*! criticalCss */
                                a {
                                    /*! criticalCss */
                                    color: #6FC0BC;
                                    position: relative;
                                    border: 1px solid transparent;
                                    padding: 0 10px;
                                    border-radius: 4px;
                                    transition: 0.3s;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &:before {
                                        /*! criticalCss */
                                        content: "\e908";
                                        font-family: 'icomoon';
                                        margin-right: 14px;
                                        font-size: 21px;
                                    }

                                    &:hover {
                                        border-color: #6FC0BC;
                                    }
                                }
                            }
                        }
                    }

                    .search-engine {
                        /*! criticalCss */
                        border: 1px solid #000;
                        margin-right: 16.5%;
                        width: 33px;
                        display: flex;
                        height: 33px;
                        align-items: center;
                        justify-content: center;
                        background: none;
                        border-radius: 4px;
                        transition: 0.3s;
                        margin: 0 auto 20px;

                        &:hover {
                            border-color: #000;
                        }
                    }



                }
            }
        }
    }

    .welcome-section {
        z-index: 99950;
    }
    .menu {
        position: relative;
        z-index: 99999;
    }
    .main-nav {
        position: relative;
    }
    .robotics-university-box {
        position: absolute;
        z-index: 99990;
        left: calc(50% + 255px);
        top: 40px;

        height: 25px;
        width: 158px !important;
        font-size: 13px;

        background-color: red;

        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom: 1px solid #6fc0bc;
        border-left: 1px solid #6fc0bc;
        border-right: 1px solid #6fc0bc;
        a {
            width: 91%;
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            text-decoration: none;
            color: #6fc0bc;

            text-align: center;
        }
    }

    .r-university-btn {
        margin-right: 15px;

        border: solid 1px #fff;
        border-radius: 4px;

        transition: all 0.25s;

        display: flex;
        align-items: center;

        margin-bottom: 14px;
        margin-right: 0;
        &:hover {
            border: solid 1px #6fc0bc;
        }
        a {
            margin: auto;
            img {
                height: 32px;
                width: auto;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    #page-header {
        /*! criticalCss */
        padding-top: 17px;
        padding-bottom: 24px;
    }
}

//lg: 992px
@include media-breakpoint-up(xl) {
    #page-header {

        .page-header-inner {

            .btn-nav {
                display: none;
            }

            .switch-lang {
                
                order: 0;

                @include mod('pillarpage') {
                    display: block;
                }

                .switch {
                    transform: scale(1);
                }
            }

            .side {
                display: flex;
                position: static;
                width: auto;
                padding-top: 0;
                padding-bottom: 0;
                min-height: auto;

                #close-nav {
                    display: none;
                }

                .main-nav {
                    display: flex;
                    align-items: center;
                    padding-bottom: 0;
                    overflow: hidden;
                    width: auto;

                    .menu {
                        display: flex;
                        list-style: none;
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style: none;
                        width: auto;
                        padding-top: 0;

                        @include mod('pillarpage') {
                            display: none;
                        }

                        li {
                            margin-right: 24px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 0;

                            a {
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 1.5;
                                text-align: center;
                                color: #000000;
                                transition: 0.3s;
                            }

                            &:nth-last-child(2) {
                                margin-right: 29px;
                            }

                            &:last-child {

                                margin-right: 12px;

                            }
                        }
                    }

                    .search-engine {
                        margin-right: 0;
                        margin-left: 0;
                        margin-bottom: 0;
                        order: 0;
                        border: 1px solid transparent;
                    }

                }
            }
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .r-university-btn {
        margin-bottom: 0;
        margin-right: 15px;
    }
    #page-header {
        .page-header-inner {

            .logo {
                .logo-note {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-right: 8px;
                    padding-left: 23px;
                    left: calc(100% + 7px);
                    top: 0;

                    &::before {
                        left: 10px;
                    }
                }
            }

            .side {

                .main-nav {

                    .menu {

                        li {
                            margin-right: 39px;
                        }
                    }
                }
            }
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}