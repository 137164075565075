/*! critical:start */
//=====includes=====
//--normalize
@import "normalize";
//--bootstrap
@import "plugins/bootstrap/bootstrap";
/*! critical:end */

@import "../css/magnific-popup.css";


//=====custom=====
//--globals (variables)
@import "globals";


//--custom mixins
@import "mixins";
//--icons

//@import "base";
@import "base";

//=====layout=====
@import "parts/header";
@import "parts/footer";

//=====animations=====
@import "parts/animations";

//=====parallax=====
@import "parts/parallax";

//=====parts=====
@import "parts/buttons";
@import "parts/count-box";
@import "parts/count-box-extend";
@import "parts/styled-block";
@import "parts/testimonial";
@import "parts/nav-arrows";
@import "parts/nav-box";
@import "parts/clients-slider";
@import "parts/benefit";
@import "parts/achivements-slider";
@import "parts/blog-slider";
@import "parts/info-container";
@import "parts/breadcrumbs";
@import "parts/accordion";
@import "parts/video-box";
@import "parts/blog-item";
@import "parts/pagination";
@import "parts/time";
@import "parts/popup";
@import "parts/popup-animations";
@import "parts/forms";
@import "parts/rounded-nav";
@import "parts/article-nav";
@import "parts/result-item";
@import "parts/search-engine";
@import "parts/cookies";
@import "parts/newsletter";

//--pages
@import "home";
@import "services";
@import "carrer";
@import "faq";
@import "blog";
@import "blog-item";
@import "contact";
@import "pillarpage";
@import "pillarpage2";
@import "landing";
@import "clients";
@import "case-study";
@import "about_us";
@import "results";
@import "policy";
