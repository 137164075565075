//breakpoint 0
@include media-breakpoint-up(xs) {

    .video-container {
        /*! criticalCss */
        position: relative;
        display: table;

        .video-button {
            /*! criticalCss */
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-decoration: none;

            .icon {
                

                .icon {
                    /*! criticalCss */
                    display: flex;
                    justify-content: center;

                    &.icon-cube {
                        /*! criticalCss */
                        font-size: 93px;
                    }

                    &.icon-triang {
                        /*! criticalCss */
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-40%) translateY(-110%);
                        font-size: 23px;
                    }
                }
            }

            .txt {
                /*! criticalCss */
                background: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.5;
                padding: 5px 23px;
                transition: 0.3s;
                white-space: nowrap;
                color:#54565A;
                text-decoration: none;
            }

            &:hover {

                .txt {
                    background: #6fc0bc;
                    color: #fff;
                    text-decoration:none;
                }
            }
        }
    }

    .services-head {

        margin-bottom: 12px;

        .col-order {
            order: -1;
        }

        p {
            font-weight: 600;
        }

        h3 {
            margin-bottom: 28px;
        }

        .desktop-none {
            display: block;
        }

        .mob-d-none {
            display: none;
        }

        

    }

    .rpa {

        margin-bottom: 47px;

        .header-mobile {

            font-size: 25px;
            color: #000;
            letter-spacing: -1.24679px;
            line-height: 30px;
            font-weight: 600;
            margin-bottom: 25px;

        }

        h3 {
            display: none;
        }

        .image {
            margin-bottom: 22px;
        }
    }

    .partres-services-section {
        margin-bottom: 85px;

        &__slider {
            position: relative;
        }

        .nav-arrows {
            margin-top: 0;
        }

        &.slick {
            .nav-arrows {
                position: absolute;
                bottom: -20px;
            }
        }

        .btn {
            display: table;
            margin: 30px auto 0;
        }

        h3 {
            margin-bottom: 9px;
        }

        .media-slider {
            padding: 42px 0;

            .item {

                padding: 42px 45px;

            }
        }

        .partners-services {

            .item {

                padding: 42px 45px;

                .img-box {
                    height: 91px;

                    img {
                        width: auto !important;
                        height: auto !important;
                    }
                }
            }
        }

        .partners-services-2 {
            
            .item {

                padding: 28px 45px 42px;

                .img-box {
                    height: 91px;
                    padding-top: 14px;
                    padding-bottom: 15px;

                    img {
                        width: auto !important;
                        height: auto !important;
                    }
                }
            }
        }

        .partners-services,
        .partners-services-2,
        .media-slider {

            margin: 0 -15px;

            margin-bottom: 5px;

            .slick-track {
                padding: 35px 0;

                .slick-slide {
                    padding: 0 15px;
                }
            }

            .item {
                display: block;
                background: #FFFFFF;
                box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.13);

                .img-box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;

                    img {
                        display: block;
                        margin: 0 auto;
                        max-height: 100%;
                        max-width: 100%;
                    }

                }

                .content {
                    text-align: center;
                    margin-bottom: 16px;
                    color: #000;
                }

                .btn-more {
                    display: table;
                    margin: 0 auto;
                }

                &:hover {
                    text-decoration: none;

                    
                }
                
            }
        }
    }

    .why-robots {
        .col-order {
            order: -1;
        }

        h3 {
            margin-bottom: 20px;
        }

        .image {
            margin-bottom: 36px;
        }

        .mobile {
            display: block;
        }

        .desktop {
            display: none;
        }
    }

    .models-table {

        margin-bottom: 66px;
        position: relative;

        .container {
            background: #FFFFFF;
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
        }

        .row {

            .col-4 {
                border-right: 1px solid #D8D8D8;
                border-bottom: 1px solid #D8D8D8;
                padding: 0 5px;
                padding-top: 30px;
                padding-bottom: 15px;
                font-weight: 500;
                font-size: 12px;
                line-height: 1.46;
                color: #000000;
                word-break: break-word;

                &:last-child {
                    border-right: none;
                }

                &:first-child {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 1.46;
                    color: #2E7571;
                }

                &:nth-child(2) {
                    border-right: none;
                }
            }

            &:last-child {

                .col-4 {
                    border-bottom: none;
                }

            }

            &:first-child {

                .col-4 {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 1.5;
                    text-transform: uppercase;
                    color: #2E7571;
                }
            }
        }

        .shadow-col {
            position: absolute;
            top: -11px;
            right: 0;
            bottom: -11px;
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
            background: #fff;
        }
    }

    .list-shadow {
        background: #FFFFFF;
        box-shadow: 0px 1.71032px 30px rgba(0, 0, 0, 0.1);
        margin: 0 -15px;
        padding: 0 15px;
        padding-top: 30px;
        padding-bottom: 24px;
        margin-bottom: 36px;
    }

    .as-a-service {
        margin-bottom: 40px;

        h3 {
            display: none;
        }

        .mobile-header {
            font-size: 25px;
            color: #000;
            letter-spacing: -1.24679px;
            line-height: 30px;
            font-weight: 600;
            margin-bottom: 35px;
        }

        .image {
            margin-bottom: 36px;
        }
    }

    .processes {

        margin-bottom: 43px;

        h3 {
            margin-bottom: 31px;
        }

        p {
            font-weight: 600;
            font-size: 15px;
            line-height: 1.6;
            color: #000000;
        }

        .item {
            font-size: 15px;
            line-height: 1.6;
            color: #272727;
            padding-top: 30px;
            margin-bottom: 42px;
            position: relative;
            text-align: justify;

            &:before {
                display: block;
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                height: 3px;
                width: 70px;
                background: #366D66;
            }
        }
    }

    .services-videos {

        margin-bottom: 60px;

        .videos-slider {

            margin-bottom: 42px;
            margin-right: -15px;

            .slick-track {
    
                .slick-slide {
                    padding-right: 15px;
    
                    .item {
                        position: relative;
                        height: 385px;
    
                        &:before {
                            position: absolute;
                            display: block;
                            content: "";
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background: rgba(11, 22, 43, 0.5);
                        }
    
                        .img-box {
    
                            height: 100%;
    
                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
    
                            }
                        }
    
                        .content-box {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: flex;
                            align-items: flex-end;
                            padding: 23px 23px 26px;
    
                            .name {
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 1.67;
                                color: #FFFFFF;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

    }




    .page-uniwersytet {
        position: relative;
        .top-headers {
            margin-top: 50px;
        }

        .knowledge-nav {
            order: -1;
            width: 100%;
            margin-bottom: 0;

            border-bottom: 1px solid #e5e5e5;
            box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.08);

            background-color: #fff;

            .container {
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;

                .nav-knowledge {
                    display: flex;
                    flex-wrap: wrap;

                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 6px 0;

                    li {
                        margin-bottom: 0;
                        font-size: 15px;
                        line-height: 1.7;
                        word-break: break-word;

                        border-radius: 6px;
                        border: 1px solid white;

                        transition: all 0.25s;
                        &:hover{
                            border-color: rgba(185, 185, 185, 0.603);
                        }

                        a {
                            display: block;
                            padding: 10px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 1.5;
                            color: #171717;
                            border-radius: 6px;
                            border: 1px solid transparent;
                            transition: .3s;

                            text-decoration: none;
                            background-color: transparent;

                            text-align: center;
                            white-space: nowrap;
                        }
                    }
                }
            }

            @media (min-width: 1270px) {
                .container {
                    max-width: 1240px;
                }
            }
            
            @media (min-width: 992px) {
                .container {
                    max-width: 960px;
                }
            }
            
            @media (min-width: 768px) {
                .container {
                    //max-width: 720px;
                }
            }
            
            @media (min-width: 576px) {
                .container {
                    //max-width: 540px;
                }
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

    .partres-services-section {
        
        .partners-services,
        .partners-services-2,
        .media-slider {

            margin: 0 -30px;

            .slick-track {
                padding: 0;
            }

            .slick-list {

                padding: 35px 15px;
            }
        }
    }

    .models-table {

        .row {

            .col-4 {
                padding: 0 15px;
                padding-top: 30px;
                padding-bottom: 15px;
                font-size: 13px;
            }
        }
    }

    .services-videos {

        .videos-slider {

            margin-right: 0;

        }
    }

    .services-head {
        .col-order {
            order: 1;
        }
    }

    .rpa {
        padding-top: 50px;
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {
    .models-table {

        .shadow-col {
            top: -30px;
            bottom: -30px;
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .video-container {

        .video-button {

            .icon {

                .icon{

                    &.icon-cube {
                        font-size: 93px;
                    }
                }
            }

            .txt {
                margin-top: 112px;
            }
        }
    }

    .services-videos {
        margin-top: 73px;
        margin-bottom: 64px;
    }

    .models-table {
        margin-bottom: 134px;
    }

    .services-head {

        padding-left: calc((100vw - 1210px - 45px)/2);
        margin-bottom: 88px;


        .desktop-none {
            display: none;
        }

        .mob-d-none {
            display: block;
        }

        .breadcrumbs {
            margin-bottom: 75px;
        }

    }

    .col-padd-left {
        padding-left: 15px;
    }

    .rpa {

        padding-top: 0;

        .header-mobile {

            display: none;

        }

        h3 {
            display: block;
        }

        .col-padd-right {
            padding-left: 55px;
        }

        .center {
            margin-left: 0;
        }
    }

    .partres-services-section {

        margin-bottom: 70px;

    }

    .why-robots {
        padding-left: calc((100vw - 1210px - 45px)/2);

        .col-order {

            order: 1;
            
        }

        h3 {

            margin-bottom: 39px;

        }
    }

    .why-robots {

        .mobile {
            display: none;
        }

        .desktop {
            display: block;
        }
    }

    .models {

        p {
            line-height: 1.6;
        }

        .models-table {
            margin-top: 55px;

            .row {

                .col-4 {

                    padding: 12px 30px 40px;
                    font-size: 15px;

                }
            }
        }
    }

    .as-a-service {

        h3 {
            display: block;
        }

        .mobile-header {
            display: none;
        }

        .col-padd-right {
            padding-left: 45px;
        }

        .center {
            margin-left: 0;
        }
    }

    .processes {

        p {
            margin-bottom: 67px;
        }

        .item {

            margin-bottom: 57px;

        }

        .btn {
            display: table;
            margin: 0 auto;
        }
    }

    .services-videos {
    
        .btn {
            display: table;
            margin: 0 auto;
        }
    }

    .services-head {
        margin-bottom: 116px;

        
    }

    .video-container {

        .icon {

            .icon {

                &.icon-cube {
                    font-size: 93px;
                }

                &.icon-triang {
                    font-size: 23px;
                }
            }
        }
    }

    .rpa {
        margin-bottom: 61px;
    }

    .partners-services,
    .partners-services-2,
    .media-slider {
        margin-bottom: 179px;
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    .as-a-service {
        .image-height {
            max-height: 620px;
        }
    }
}


