//breakpoint 0
@include media-breakpoint-up(xs) {
    .nav-arrows {
        /*! criticalCss */
        display: flex;
        margin: -10px -15px 0 0;

        .icon {
            /*! criticalCss */
            display: inline-flex;
            align-self: flex-start;
            padding: 10px;
            margin: 5px;
            border: 1px solid transparent;
            border-radius: 4px;
            opacity: 1;
            transition: 0.3s;
            cursor: pointer;
            outline: none;

            &:first-child {
                /*! criticalCss */
                transform: rotate(180deg);
            }

            &:hover {
                border-color: #6FC0BC;
                opacity: 1;
            }

            &:hover,
            &:active {
                outline: none;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .nav-arrows {
        margin: 0;
        margin-top: 61px;
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}