//breakpoint 0
@include media-breakpoint-up(xs) {

    .mfp-container {
        padding: 6px;
    }

    .mfp-bg {
        background: rgba(255, 255, 255, 0.8);
    }

    .mfp-img-mobile {

        figure {
            overflow: auto;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
        }

        .mfp-img {
            max-width: none;
            box-shadow: none;
        }
    }

    .mfp-close-btn-in .mfp-close {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 0;
        margin: 20px;
        background: url('../img/icons/close-2.svg') 50% 50% no-repeat;
        transition: 0.3s;
    }

    .white-popup {
        /*! criticalCss */
        position: relative;
        background: #FFF;
        padding: 68px 30px 30px;
        width:auto;
        max-width: 653px;
        margin: 0 auto; 
        /*background-image: url('../img/bg-popup-left.svg'), url('../img/bg-popup-right.svg');*/
        background-position: 0 50%, 100% 50%;
        background-repeat: no-repeat, no-repeat;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.13);


        .name {
            text-align: center;
            margin-bottom: 60px;
            font-weight: 600;
            font-size: 24px;
            line-height: 1.35;
            text-align: center;
            color: #2C2E30;
        }

        .list {
            padding-left: 0;
            margin-bottom: 60px;
        }

        .popup-columns {

            .column {
                /*! criticalCss */
                display:flex;
                flex-direction: column;
            }

            .column--center{
                /*! criticalCss */
                justify-content: center;
            }

        }

        @include mod('wide') {
            /*! criticalCss */
            max-width: 1440px;
            padding: 0 !important;

            .mfp-close {
                opacity: 1;
                transition: 0.3s;

                &:hover {
                    opacity: 0.65;
                }
            }

            .popup-columns {

                .column {

                    h3 {
                        font-size: 24px;
                        margin-bottom: 34px;
                    }

                    .form {

                        label {
                            color: #000;
                        }

                        .form-control {
                            padding: 9px;
                        }

                        @include el('row'){
                            /*! criticalCss */
                            margin-bottom: 24px;
                            &:last-child{
                                margin-bottom: 34px;
                            }
                        }

                    }

                    @include mod('side') {
                        width: 100%;
                        padding-bottom: 64px;
                        background: url('../img/bg-popup.png') 100% 50%/cover no-repeat;
                        margin-left:auto;

                        .testimonial {
                            padding: 120px 15px 64px;
                            margin-top: 0;

                            @include el('logo-light') {
                                display: block;
                                margin: 0 auto 39px;
                            }

                            @include el('quote') {
                                font-size: 16px;
                            }

                            @include el('person') {

                                .position {
                                    margin-bottom: 0;
                                }

                                .name_name {
                                    font-family: 'aileronbold';
                                    font-size: 16px;
                                    line-height: 19px;
                                    text-transform: uppercase;
                                    color: #FFFFFF;
                                    text-align: center;
                                    margin-bottom: 12px;
                                }

                            }
                        }

                        .companies {

                            .header {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 18px;
                                text-align: center;
                                letter-spacing: -1.24679px;
                                color: #FFFFFF;
                                margin-bottom: 43px;
                                padding: 0 15px;
                            }

                            .items {
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                padding: 0 15px;

                                .item {
                                    padding: 0 10px;
                                }
                            }
                        }
                    }

                }
            }

            .form-container {
                /*! criticalCss */
                padding: 45px 6.4vw 45px 6.4vw;
                margin-bottom: 0;
                box-shadow: none;
                max-width: none;
            }
        }

    }

}

//sm: 480px
@include media-breakpoint-up(sm) {
    .white-popup {

        @include mod('wide') {

            .popup-columns {

                .column {

                    @include mod('side') {

                        .testimonial {

                            padding: 120px 30px 64px;

                        }

                        .companies {

                            .items {
                                padding: 0 44px;
                            }
                        }

                    }

                }
            }
        }

    }
}

//md: 768px
@include media-breakpoint-up(md) {
    .white-popup {

        padding: 30px 72px 30px;

        .name {
            font-size: 34px;
            margin-bottom: 24px;
        }

        .list {
            padding-left: 88px;
            margin-bottom: 45px;
        }

    }

}

//lg: 992px
@include media-breakpoint-up(lg) {
    .white-popup {

        @include mod('wide') {

            .popup-columns {

                display: flex;

                .column {


                    @include mod('side') {
                        width: 440px;
                        flex-shrink: 0;

                        .testimonial {

                            padding: 120px 82px 64px;

                        }

                        .companies {

                            .items {
                                justify-content: space-between;
                            }
                        }
                    }

                }
            }

            .form-container {
                padding: 45px 11.45vw 45px 6.4vw;
            }
        }

    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .white-popup {

        @include mod('wide') {

            .popup-columns {

                .column {

                    @include mod('side') {
                        width: 540px;
                    }

                }
            }
        }

    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}