//breakpoint 0
@include media-breakpoint-up(xs) {

    .btn {
      /*! criticalCss */
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.46;
        text-align: center;
        padding: 13px 15px 14px;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        border: none;
        border-radius: 0;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        background: rgba(111,192,188, 1);
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        border: 2px solid transparent;
        position: relative;
        margin-bottom: 27px;
        cursor: pointer;

        &:after {
          /*! criticalCss */
          content: "\e930";
          font-family: 'icomoon';
          font-size: 8px;
          color: #fff;
          margin-left: 10px;
          margin-top: -2px;
        }

        &:hover {
          &:after {
            color: rgba(111,192,188, 1);
          }
        }

        &:visited {
          /*! criticalCss */
          color: #fff;
        }

        @include mod('white') {
          border-color: rgba(111,192,188, 1);
          color: rgba(111,192,188, 1);
          background: #fff;

          &:after {
            color: rgba(111,192,188, 1)
          }

          &:before {
            background: rgba(111,192,188, 1) !important;
          }
  
          &:hover {
            color: #fff !important;

            &:after {
              color: #fff;
            }
          }
  
          &:visited {
            color: rgba(111,192,188, 1)
          }
        }
      }
      .btn:before {
        /*! criticalCss */
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.25s;
        transition-duration: 0.25s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
      .btn:hover, .btn:focus, .btn:active {
        color: rgba(111,192,188, 1);
        border-color: rgba(111,192,188, 1);
        text-decoration: none;
      }
      .btn:hover:before, .btn:focus:before, .btn:active:before {
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      @include mod('underline') {
        color: #000;
      }

      
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}