//breakpoint 0
@include media-breakpoint-up(xs) {
    .blog-slider {
        /*! criticalCss */
        white-space: nowrap;
        margin-bottom: 55px;
        display: flex;
        margin-right: -15px;
        overflow: hidden;

        .slick-slide {
            /*! criticalCss */
            padding-right: 15px;
        }

        .item {
            /*! criticalCss */
            display: inline-block;
            flex-shrink: 0;
            width: 257px;
            white-space: normal;
            box-shadow: 0px 1.71032px 30px rgba(0, 0, 0, 0.1);

            &:hover {
                text-decoration: none;

                .img-box {

                    img {
                        transform: scale(1.1);
                    }
                }

                .content-box {
                    background: #f0f9f8;
                }
            }

            .img-box {
                /*! criticalCss */
                height: 167px;
                overflow: hidden;

                img {
                    /*! criticalCss */
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.3s;
                    transform: scale(1);
                }
            }

            .content-box {
                /*! criticalCss */
                background: #fff;
                padding: 18px;
                padding-bottom: 29px;
                text-align: center;
                transition: 0.3s;

                h4 {
                    /*! criticalCss */
                    font-weight: 600;
                    font-size: 20.5238px;
                    line-height: 1.42;
                    text-align: center;
                    color: #2C2E30;
                    margin-bottom: 16px;
                    text-align: center;
                }

                p {
                    /*! criticalCss */
                    font-size: 15px;
                    line-height: 1.6;
                    text-align: center;
                    color: #54565A;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}