//breakpoint 0
@include media-breakpoint-up(xs) {
    .count-box {
        /*! criticalCss */
        text-align: center;
        margin-bottom: 71px;
        padding: 0 23%;

        @include el('num') {
            /*! criticalCss */
            font-size: 50px;
            line-height: 1.5;
            letter-spacing: -1.24679px;
            color: #6FC0BC;
            font-weight: 700;
            margin-bottom: 7px;
        }

        @include el('txt') {
            /*! criticalCss */
            font-weight: 500;
            font-size: 15px;
            line-height: 1.4;
            text-align: center;
            letter-spacing: -0.350503px;
            color: #000000;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .count-box {
        margin-bottom: 86px;
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}