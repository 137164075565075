//breakpoint 0
@include media-breakpoint-up(xs) {
    .nav-box {
        /*! criticalCss */
        display: flex;
        justify-content: space-between;
        margin-bottom: 29px;
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .nav-box {
        display: inline-block;

        .icon {
            margin: 0;
            margin-right: 10px;
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}