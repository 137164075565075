@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?ggqdbj');
  src:  url('icomoon.eot?ggqdbj#iefix') format('embedded-opentype'),
    url('icomoon.ttf?ggqdbj') format('truetype'),
    url('icomoon.woff?ggqdbj') format('woff'),
    url('icomoon.svg?ggqdbj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon-brands';
  src:  url('brands/icomoon-brands.eot?ggqdbj');
  src:  url('brands/icomoon-brands.eot?ggqdbj#iefix') format('embedded-opentype'),
  url('brands/icomoon-brands.ttf?ggqdbj') format('truetype'),
  url('brands/icomoon-brands.woff?ggqdbj') format('woff'),
  url('brands/icomoon-brands.svg?ggqdbj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /*! criticalCss */
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.brandicon{
  font-family: 'icomoon-brands' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brandicon-youtube:before {
  content: "\ec97";
  color: #fff;
}

.icon-step-8:before {
  content: "\e93a";
}
.icon-step-7:before {
  content: "\e93b";
}
.icon-filos-1:before {
  content: "\e936";
}
.icon-filos-2:before {
  content: "\e937";
}
.icon-filos-3:before {
  content: "\e938";
}
.icon-filos-4:before {
  content: "\e939";
}
.icon-feature-5:before {
  content: "\e931";
}
.icon-feature-4:before {
  content: "\e932";
}
.icon-feature-3:before {
  content: "\e933";
}
.icon-feature-2:before {
  content: "\e934";
}
.icon-feature-1:before {
  content: "\e935";
}
.icon-small-arrow-right:before {
  content: "\e930";
  color: #fff;
}
.icon-close .path1:before {
  content: "\e92e";
  color: rgb(111, 192, 188);
}
.icon-close .path2:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-freehand-arrow-2:before {
  content: "\e92d";
  color: #6fc0bc;
}
.icon-close:before {
  /*! criticalCss */
  content: "\e92c";
}
.icon-cube-5:before {
  content: "\e92b";
  color: #499e96;
}
.icon-cube-4:before {
  /*! criticalCss */
  content: "\e92a";
  color: #6fc0bc;
}
.icon-envelope:before {
  content: "\e928";
}
.icon-pin:before {
  content: "\e929";
}
.icon-fb:before {
  content: "\e925";
  color: #9b9b9b;
}
.icon-in:before {
  content: "\e926";
  color: #9b9b9b;
}
.icon-tw:before {
  content: "\e927";
  color: #9b9b9b;
}
.icon-arrow-down-2:before {
  content: "\e924";
}
.icon-arrow-right:before {
  content: "\e923";
}
.icon-clock:before {
  content: "\e922";
  color: #999;
}
.icon-arrow-down:before {
  content: "\e921";
  color: #6fc0bc;
}
.icon-our-benefit-1:before {
  content: "\e919";
}
.icon-our-benefit-2:before {
  content: "\e91a";
}
.icon-our-benefit-3:before {
  content: "\e91b";
}
.icon-our-benefit-4:before {
  content: "\e91c";
}
.icon-our-benefit-5:before {
  content: "\e91d";
}
.icon-our-benefit-6:before {
  content: "\e91e";
}
.icon-our-benefit-7:before {
  content: "\e91f";
}
.icon-our-benefit-8:before {
  content: "\e920";
}
.icon-cube-2:before {
  /*! criticalCss */
  content: "\e918";
}
.icon-arrow:before {
  /*! criticalCss */
  content: "\e900";
  color: #6ebdb9;
}
.icon-arrow-2:before {
  content: "\e901";
}
.icon-loupe:before {
  /*! criticalCss */
  content: "\e90b";
}
.icon-benefit-1:before {
  /*! criticalCss */
  content: "\e902";
}
.icon-benefit-2:before {
  /*! criticalCss */
  content: "\e903";
}
.icon-benefit-3:before {
  /*! criticalCss */
  content: "\e904";
}
.icon-benefit-4:before {
  /*! criticalCss */
  content: "\e905";
}
.icon-benefit-5:before {
  /*! criticalCss */
  content: "\e906";
}
.icon-benefit-6:before {
  /*! criticalCss */
  content: "\e907";
}
.icon-book:before {
  content: "\e908";
  color: #6fc0bc;
}
.icon-cube:before {
  /*! criticalCss */
  content: "\e909";
  color: #6fc0bc;
}
.icon-freehand-arrow:before {
  content: "\e90a";
  color: #6fc0bc;
}
.icon-nav:before {
  /*! criticalCss */
  content: "\e90c";
}
.icon-phone:before {
  /*! criticalCss */
  content: "\e90d";
  color: #abff56;
}
.icon-social-fb:before {
  /*! criticalCss */
  content: "\e90e";
  color: #fff;
}
.icon-social-in:before {
  /*! criticalCss */
  content: "\e90f";
  color: #fff;
}
.icon-social-yt:before {
  /*! criticalCss */
  content: "\ea9d";
  color: #fff;
}
.icon-step-1:before {
  /*! criticalCss */
  content: "\e910";
}
.icon-step-2:before {
  /*! criticalCss */
  content: "\e911";
}
.icon-step-3:before {
  /*! criticalCss */
  content: "\e912";
}
.icon-step-4:before {
  /*! criticalCss */
  content: "\e913";
}
.icon-step-5:before {
  /*! criticalCss */
  content: "\e914";
}
.icon-step-6:before {
  content: "\e915";
}
.icon-tick:before {
  content: "\e916";
  color: #6fc0bc;
}
.icon-triang:before {
  /*! criticalCss */
  content: "\e917";
  color: #fff;
}
