@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin chromefix{
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

@mixin el($element){
    &__#{$element}{
        @content;
    }
}
@mixin mod($modifier){
    &--#{$modifier}{
        @content;
    }
}