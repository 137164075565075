@import './../fonts/icons/icons.css';
@import './../css/slick.css';
@import './../css/aos.css';
@import './../css/perfect-scrollbar.css';

.mfp-figure:after{
        display:none;
    }
    
    .mfp-image-holder .mfp-content{
        background-color:#fff;
        padding:30px;
    }
    
    .mfp-with-zoom .mfp-container,
    .mfp-with-zoom.mfp-bg {
        opacity: 0;
        -webkit-backface-visibility: hidden;
        /* ideally, transition speed should match zoom duration */
        -webkit-transition: all 0.3s ease-out; 
        -moz-transition: all 0.3s ease-out; 
        -o-transition: all 0.3s ease-out; 
        transition: all 0.3s ease-out;
    }
    
    .mfp-with-zoom.mfp-ready .mfp-container {
            opacity: 1;
    }
    .mfp-with-zoom.mfp-ready.mfp-bg {
            opacity: 0.8;
    }
    
    .mfp-with-zoom.mfp-removing .mfp-container, 
    .mfp-with-zoom.mfp-removing.mfp-bg {
        opacity: 0;
    }

//breakpoint 0
@include media-breakpoint-up(xs) {
    
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    
    html {
        /*! criticalCss */
        font-size: .625rem;
        min-height: 100%;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
    
    body {
        /*! criticalCss */
        line-height: 1.86;
        font-size: 1.5rem;
        color: #54565A;
        font-family: 'Poppins', sans-serif;
        transition: 0.2s;
    }

    html,
    body{
        /*! criticalCss */
        height: 100%;
    }

    input, textarea {
        /*! criticalCss */
        -webkit-appearance: none;
        border-radius: 0; 
    }
    
     ::-moz-selection {
        background: #6fc0bc;
        color: #fff;
    }
    
     ::selection {
        background: #6fc0bc;
        color: #fff;
    }

    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        /*! criticalCss */
        margin: 0;
    }

    h1 {
        /*! criticalCss */
        font-size: 34px;
        color: #000;
        letter-spacing: -1.24679px;
        line-height: 1.35;
        font-weight: 600;
        margin-bottom: 21px;
    }

    h2 {
        /*! criticalCss */
        font-size: 16px;
        letter-spacing: -0.13838px;
        font-weight: 600;
        color: #366D66;
        padding-bottom: 15px;
        position: relative;
        margin-bottom: 10px;

        &.light-green {
            /*! criticalCss */
            color: #6FC0BC;

            &:after {
                /*! criticalCss */
                background: #6FC0BC;
            }
        }

        &:after {
            /*! criticalCss */
            display: block;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50px;
            height: 3px;
            background: #366D66;
        }

        &.center {
            &:after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    h3{
        /*! criticalCss */
        font-size: 34px;
        color: #000;
        letter-spacing: -1.24679px;
        line-height: 1.35;
        font-weight: 600;
        margin-bottom: 21px;
    }

    h4{
        /*! criticalCss */
        font-size: 20px;
    }

    p {
        /*! criticalCss */
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 24px;
        margin-top: 24px;
        text-align: justify;
    }

    i,
    i:before{

    }
    
    img{
        /*! criticalCss */
      max-width: 100%;
      height: auto;
    }

    img.mfp-img {
        padding: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0,0,0, .8);
    }

    .btn-more {
        /*! criticalCss */
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 1.2;
        text-align: center;
        color: #000000;
        transition: 0.3s;
        padding-bottom: 9px;

        &:after {
            /*! criticalCss */
            content: "\e901";
            font-family: 'icomoon';
            font-size: 8px;
            margin-left: 8px;
        }

        &:before {
            /*! criticalCss */
            display: block;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 3px;
            background: #6FC0BC;
        }

        &:hover {
            color: #6fc0bc;
            text-decoration: none;
        }
    }
    
    .editable {

        ul {
            padding-left: 0;
            list-style: none;
            position: relative;
            margin-bottom: 35px;

            li {
                position: relative;
                padding-left: 38px;
                font-size: 15px;
                line-height: 1.86;
                margin-bottom: 21px;
                text-align: justify;
    
                &:before {
                    display: block;
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 4px;
                    width: 20px;
                    height: 20px;
                    background: #ECF9FA url('../img/icons/tick-2.svg') 50% 50% no-repeat;
        
                }
            }
        }
    }


    .list {
        padding-left: 0;
        list-style: none;
        position: relative;
        margin-bottom: 35px;

        li {
            position: relative;
            padding-left: 38px;
            font-size: 15px;
            line-height: 1.86;
            margin-bottom: 21px;
            text-align: justify;

            &:before {
                display: block;
                position: absolute;
                content: "";
                left: 0;
                top: 4px;
                width: 20px;
                height: 20px;
                background: #ECF9FA url('../img/icons/tick-2.svg') 50% 50% no-repeat;
    
            }

        }

    }

    .center {
        /*! criticalCss */
        display: table;
        margin-left: auto;
        margin-right: auto;
    }

    .center-txt {
        text-align: center;
    }

    ul {
        /*! criticalCss */
        padding-left: 15px;
        margin-bottom: 30px;

        li {
            /*! criticalCss */
            margin-bottom: 14px;
            font-size: 15px;
            line-height: 1.7;
            word-break: break-word;
        }
    }

    ul ul {
        margin: 30px 0;
        list-style: disc;
    }

    .page-bg {
        padding-top: 22px;
        position: relative;
        background: url('../img/bg-section-1.svg') 100% 0 no-repeat;
        background-size: 75%;

        @include mod('no-padding') {
            padding-top: 0;
        }
    }

    .green-bg {
        background: linear-gradient(180deg, rgba(236, 249, 250, 0) -5.7%, #ECF9FA 79.63%);
        position: relative;

        .knowledge-nav {
            order: -1;
            width: 100%;
            margin-bottom: 0;

            border-bottom: 1px solid #e5e5e5;
            box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.08);

            background-color: #fff;

            .container {
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;

                .nav-knowledge {
                    display: flex;
                    flex-wrap: wrap;

                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    padding: 6px 0;

                    li {
                        margin-bottom: 0;
                        font-size: 15px;
                        line-height: 1.7;
                        word-break: break-word;

                        border-radius: 6px;
                        border: 1px solid white;

                        transition: all 0.25s;
                        &:hover{
                            border-color: rgba(185, 185, 185, 0.603);
                        }

                        a {
                            display: block;
                            padding: 10px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 1.5;
                            color: #171717;
                            border-radius: 6px;
                            border: 1px solid transparent;
                            transition: .3s;

                            text-decoration: none;
                            background-color: transparent;

                            text-align: center;
                            white-space: nowrap;
                        }
                    }
                }
            }

            @media (min-width: 1270px) {
                .container {
                    max-width: 1240px;
                }
            }
            
            @media (min-width: 992px) {
                .container {
                    max-width: 960px;
                }
            }
            
            @media (min-width: 768px) {
                .container {
                    //max-width: 720px;
                }
            }
            
            @media (min-width: 576px) {
                .container {
                    //max-width: 540px;
                }
            }
        }
    }

    .underline-header {
        font-size: 16px;
        letter-spacing: -0.13838px;
        font-weight: 600;
        color: #366D66;
        padding-bottom: 15px;
        position: relative;
        margin-bottom: 28px;

        &:after {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50px;
            height: 3px;
            background: #366D66;
        }
    }

    .header-line-up {
        position: relative;

        &:after {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            top: -16px;
            width: 50px;
            height: 3px;
            background: #366D66;
        }
    }

    cite {
        font-size: 18px;
        line-height: 2;
        font-weight: 600;
        text-align: justify;
    }

    .company-slider-container {
        margin-right: -15px;
    }

}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .page-bg {
        background-size: auto;
    }

    h2 {
        /*! criticalCss */
        margin-bottom: 14px;
    }

    
}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

    h1 {
        font-size: 25px;
    }

    h2 {
        font-size: 16px;
    }

    h3{
        font-size: 25px;
    }

    h4{
        font-size: 20px;
    }

    .margin-top-35 {
        margin-top: 60px;
    }

    .col-padd-left {
        padding-left: calc((100vw - 1210px - 15px)/2);
    }

    .col-padd-right {
        padding-right: calc((100vw - 1210px - 15px)/2);
    }

    .image-offset-left {
        display: block;
        margin-left: auto !important;
    }

    .fluid-section {
        .col-padd-left {
            padding-left: calc((100vw - 1210px - 45px)/2);
        }
    }

    .company-slider-container {
        padding-left: calc((100vw - 1210px - 45px)/2);
        overflow: hidden;
        padding-top: 16px;
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}

@media (max-width: 360px) {
    h3 {
        /*! criticalCss */
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .container {
        /*! criticalCss */
        max-width: none !important;
    }
}

@media (min-width: 1270px) and (min-height: 600px) {
    .welcome-height {
        height: calc(100vh - 112px);
    }

    img.welcome-height {
        object-fit: cover;
        object-position: 0 0;
    }
}