//breakpoint 0
@include media-breakpoint-up(xs) {

  /* main styles */
  .switch {
    position: relative;
  }
  .switch input {
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }
  .switch input:checked {
    z-index: 1;
  }
  .switch input:checked + label {
    opacity: 1;
    cursor: default;
    color: #6FC0BC;
    font-weight: 600;
  }
  .switch input:not(:checked) + label:hover {

  }
  .switch label {
    color: #000000;
    opacity: 1;
    transition: opacity 0.25s ease;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }
  .switch .toggle-outside {
    height: 100%;
    border-radius: 2rem;
    padding: 2px;
    overflow: hidden;
    transition: 0.25s ease all;
  }
  .switch .toggle-inside {
    border-radius: 5rem;
    background: #4a4a4a;
    position: absolute;
    transition: 0.25s ease all;
  }
  .switch--horizontal {
    height: 19px;
    margin: 0 auto;
    font-size: 0;
  }
  .switch--horizontal input {
    height: 19px;
    width: 36px;
    left: 6rem;
    margin: 0;
  }
  .switch--horizontal label {
    font-size: 15px;
    line-height: 19px;
    display: inline-block;
    width: 66px;
    height: 19px;
    margin: 0;
    text-align: center;
    font-weight: 400;
  }
  .switch--horizontal label:last-of-type {
    margin-left: 0;
  }
  .switch--horizontal .toggle-outside {
    background: #fff;
    border: 1px solid #6FC0BC;
    position: absolute;
    width: 35px;
    left: 47px;
    z-index: -1;
  }
  .switch--horizontal .toggle-inside {
    height: 13px;
    width: 13px;
    background: #6FC0BC;
  }
  .switch--horizontal input:checked ~ .toggle-outside .toggle-inside {
    left: 3px;
  }
  .switch--horizontal input ~ input:checked ~ .toggle-outside .toggle-inside {
    left: 17px;
  }
  .switch--vertical {
    width: 12rem;
    height: 6rem;
  }
  .switch--vertical input {
    height: 100%;
    width: 3rem;
    right: 0;
    margin: 0;
  }
  .switch--vertical label {
    font-size: 1.5rem;
    line-height: 3rem;
    display: block;
    width: 8rem;
    height: 50%;
    margin: 0;
    text-align: center;
  }
  .switch--vertical .toggle-outside {
    background: #fff;
    position: absolute;
    width: 3rem;
    height: 100%;
    right: 0;
    top: 0;
  }
  .switch--vertical .toggle-inside {
    height: 2.5rem;
    left: 0.25rem;
    top: 0.25rem;
    width: 2.5rem;
  }
  .switch--vertical input:checked ~ .toggle-outside .toggle-inside {
    top: 0.25rem;
  }
  .switch--vertical input ~ input:checked ~ .toggle-outside .toggle-inside {
    top: 3.25rem;
  }
  .switch--no-label label {
    width: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }
  .switch--no-label input:checked ~ .toggle-outside .toggle-inside {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .switch--no-label input ~ input:checked ~ .toggle-outside {
    background: #fff;
  }
  .switch--no-label input ~ input:checked ~ .toggle-outside .toggle-inside {
    background: #2ecc71;
  }
  .switch--no-label.switch--vertical {
    width: 3rem;
  }
  .switch--no-label.switch--horizontal {
    width: 6rem;
  }
  .switch--no-label.switch--horizontal input,
  .switch--no-label.switch--horizontal .toggle-outside {
    left: 0;
  }
  .switch--expanding-inner input:checked + label:hover ~ .toggle-outside .toggle-inside {
    height: 2.5rem;
    width: 2.5rem;
  }
  .switch--expanding-inner.switch--horizontal input:hover ~ .toggle-outside .toggle-inside {
    width: 3.5rem;
  }
  .switch--expanding-inner.switch--horizontal input:hover ~ input:checked ~ .toggle-outside .toggle-inside {
    left: 2.25rem;
  }
  .switch--expanding-inner.switch--vertical input:hover ~ .toggle-outside .toggle-inside {
    height: 3.5rem;
  }
  .switch--expanding-inner.switch--vertical input:hover ~ input:checked ~ .toggle-outside .toggle-inside {
    top: 2.25rem;
  }
  /* mixin */

  .welcome-section {

    margin-bottom: 62px;

    .col-order {
      order: -1;
      padding: 0;
      margin-bottom: 29px;
    }

    p {
      font-weight: 600;
    }


    .welcome-section__image {
      position: relative;
      display: block;

      img {
        width: 100%;
      }
    }

    .btn-note {
      position: relative;
      padding-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #000;

      &:before {
        display: inline-block;
        content: "\e90a";
        font-family: 'icomoon';
        font-size: 78px;
        color: #6FC0BC;
        margin-left: -15px;
        line-height: 1;
        transform: rotate(-25.39deg);
      }

      .btn-note__txt {
        display: inline-flex;
        align-items: center;
        margin-left: 7px;
        position: relative;
        bottom: 4px;

        .icon-phone {
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .benefits {
    h3 {
      text-align: justify;
    }
  }

  .solutions {
    h2 {
      margin-bottom: 26px;
    }

    h3 {
      font-size: 20px;
      text-align: justify;
    }
  }

  .testimonials {
    padding-top: 32px;
    padding-bottom: 44px;
    margin-bottom: 54px;
    background: url('../img/bg-testimonials.jpg') 50% 0/cover no-repeat;

    h3 {
      color: #fff;
    }

    .testimonial {
      margin-top: 0;
    }

    .testimonials-slider {

      padding-top: 43px;

      .slick-dots {
        display: flex;
        justify-content: center;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: block;
            text-indent: -999em;
            border: none;
            margin: 0 9px;
            position: relative;
            background: none;

            &:before {
              display: block;
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              transform: translateX(-50%) translateY(-50%);
              background: #fff;
            }
          }

          &.slick-active {
            button {

              &:before {
                display: block;
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                transform: translateX(-50%) translateY(-50%);
                background: #6FC0BC;
              }
            }
          }
        }
      }
    }
  }

  .clients {
    margin-bottom: 38px;

    .button {
      display: flex;
      margin: 0 auto;
    }


  }

  .process {

    position: relative;

    .name {
      display: block;
      font-family: 'aileronregular';
      font-weight: 700;
      font-size: 15px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #000000;
      opacity: 0.7;
      margin-bottom: 31px;
    }

    .process-periods {

      position: absolute;
      left: 59px;
      top: 52px;

      .item {
        width: 20px;
        border: 1px dotted #EBEBEB;
        border-right: none;
        margin-bottom: 15px;
        position: relative;

        &:first-child {
          height: 423px;
        }

        &:nth-child(2) {
          height: 124px;
        }

        &:nth-child(3) {
          height: 90px;
        }

        .txt {
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: -56px;
          transform: rotate(-90deg) translateX(30%);
          font-size: 14px;
          color: #C2C2C2;
        }
      }

      @include mod('short') {

        left: 30px;

        .item {
          border-color: #6FC0BC;

          &:first-child {
            height: 130px;
          }

          &:nth-child(2) {
            height: 391px;
          }

          &:nth-child(3) {
            height: 268px;
          }

          .txt {
            color: #6FC0BC;
            font-weight: 600;
          }
        }
      }
    }

    .process-steps {
      padding-top: 52px;
      margin-bottom: 56px;
      position: relative;
      padding-left: 66px;

      .period {
        position: relative;

        &:after {
          width: 20px;
          border: 1px dotted #EBEBEB;
          border-right: none;
          position: relative;
          content: "";
          position: absolute;
          left: -40px;
          bottom: 0;
          top: 0;
        }

        &:before {
          display: block;
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: -110px;
          transform: rotate(-90deg) translateX(45%);
          font-size: 14px;
          width: 85px;
          color: #C2C2C2;
        }

        &:first-child {

          &:after {
            bottom: -64px;
          }

          &:before {
            content: "4 dni"
          }
        }

        &:nth-child(2) {
          &:after {
            top: 50px;
          }

          &:before {
            content: "Decyzja"
          }
        }

        &:nth-child(3) {
          &:before {
            content: "Tydzień"
          }
        }

        &:last-child {

          &:after {
            top: -14px;
          }

          .process-steps__item:before {
            display: none;
          }
        }
      }

      @include mod('short') {
        padding-left: 73px;

        .period {

          padding-bottom: 0;

          &:before {
            color: #6FC0BC;
            font-weight: 600;
            transform: rotate(-90deg) translateX(25%);
          }

          &:after {
            border-color: #6FC0BC;
            bottom: 24px !important;
            top: 0 !important;
          }

          &:nth-child(2) {

            .process-steps__item {
              padding: 180px 0 160px;
            }
          }

          &:last-child {

            .process-steps__item {
              padding: 110px 0 25px;

              &:before {
                bottom: auto;
                height: 110px;
              }
            }

            &:after {
              bottom: 0 !important;
            }
          }

          &:first-child {
            &:before {
              content: "Trdzień 1"
            }
          }

          &:nth-child(2) {
            &:before {
              content: "Tydzień 2-4"
            }
          }

          &:nth-child(3) {
            &:before {
              content: "Tydzień 5-6"
            }
          }
        }

        .process-steps__item {

          margin-bottom: 0;
          position: relative;

          &:first-child {
            padding-bottom: 146px;
          }

          &:nth-child(2) {
            padding-bottom: 204px;

          }

          &:before {
            display: block !important;
            position: absolute;
            content: "";
            left: 37px;
            top: 0;
            bottom: 0;
            width: 1px;
          }

          &:last-child {

            padding-bottom: 56px;

          }

          .step_icon {
            background: #fff;
          }

          .step_content {

            .txt {

              cursor: default;

              &:hover {
                color: inherit;
              }
            }
          }
        }
      }

      @include el('item') {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 31px;
        align-items: flex-start;
        position: relative;

        &:before {
          display: block;
          content: "";
          position: absolute;
          left: 37px;
          top: 85px;
          bottom: -31px;
          width: 1px;
          background: #DAF3F5;
        }

        .step_icon {
          position: relative;
          width: 75px;
          height: 85px;
          margin-right: 13px;
          flex-shrink: 0;

          .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            &:first-child {
              font-size: 33px;

              &:before {
                color: #18202D;
              }
            }

            &.icon-cube-2 {
              font-size: 85px;

              &:before {
                color: #18202D;
              }
            }
          }
        }

        .step_content {

          width: calc(100% - 88px);
          padding-top: 5px;

          .title {
            font-weight: 600;
            font-size: 15px;
            line-height: 1.46;
            letter-spacing: -0.13838px;
            color: #366D66;
          }

          .txt {
            font-weight: 600;
            font-size: 15px;
            line-height: 1.46;
            color: #2C2E3F;
            transition: 0.3;
            cursor: pointer;

            &:hover {
              color: #366D66;
            }
          }
        }

        .step-more {
          padding-left: 88px;
          text-align: justify;
        }
      }
    }
  }

  .en {
    .process {
      .process-steps {
        .period {
          &:first-child {
            &:before {
              content: "4 days";
            }
          }

          &:nth-child(2) {
            &:before {
              content: "Decision";
            }
          }

          &:nth-child(3) {
            &:before {
              content: "Week";
            }
          }
        }

        @include mod('short') {
          .period {
            &:first-child {
              &:before {
                content: "Week 1";
              }
            }

            &:nth-child(2) {
              &:before {
                content: "Week 2-4";
              }
            }

            &:nth-child(3) {
              &:before {
                content: "Week 5-6";
              }
            }
          }
        }
      }
    }
  }

  .achievements {
    padding-top: 20px;
  }

  .about_us {

    .button {
      display: table;
      margin: 32px auto 92px;
      position: relative;
    }
  }

  .blog {
    padding-top: 41px;
    padding-bottom: 58px;
    margin-bottom: 58px;
    background: url('../img/bg-blog.jpg') 50% 0/cover no-repeat;

    h3 {
      color: #fff;
      margin-bottom: 38px;
    }

    .nav-arrows {
      display: none;
    }

    .slick-track {
      padding: 30px 0;
    }

    .button {
      display: table;
      margin: 0 auto;
    }
  }

  .partners {
    margin-bottom: 49px;
    overflow: hidden;
    padding-top: 20px;

    .partners-inner {

      position: relative;
      margin-left: -15px;
      margin-right: -15px;
      white-space: nowrap;
      text-align: center;
      overflow: auto;
      padding-bottom: 30px;
      padding-left: 15px;

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        display: inline-block;
        margin-right: 60px;
        height: 63px;

        img {
          max-height: 100%;
          display: block;
        }

        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }

      .ps__rail-x {

        opacity: 0.5;

      }
    }
  }

  .contact-box {
    /*! criticalCss */
    padding: 49px 23px 33px;
    margin-bottom: -83px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1;

    .header {
      /*! criticalCss */
      font-weight: 600;
      font-size: 25px;
      line-height: 1.52;
      letter-spacing: 0.375px;
      color: #366D66;
      margin-bottom: 17px;
    }

    @include el('icons') {
      /*! criticalCss */
      display: flex;
      margin-bottom: 45px;

      .item {
        /*! criticalCss */
        display: block;
        position: relative;
        width: 34px;
        height: 39px;
        margin-right: 11px;

        &:hover {
          .icon {
            &:first-child {

              &:before {
                color: #6fc0bc;
              }
            }
          }
        }

        .icon {
          /*! criticalCss */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          line-height: 1;

          &:first-child {
            /*! criticalCss */
            font-size: 50px;


            &:before {
              /*! criticalCss */
              color: #132138;
              transition: 0.3s;
            }
          }

          &.icon-social {
            /*! criticalCss */
            font-size: 12px;
            transform: translateX(-50%) translateY(-55%);
          }
        }


        .icon-microphone {
          position: absolute;
          z-index: 5;
          width: 18px;
          height: 18px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    @include el('person') {
      /*! criticalCss */
      display: flex;
      align-items: center;
      margin-bottom: 71px;

      .img-box {
        /*! criticalCss */
        width: 91px;
        height: 91px;
        flex-shrink: 0;
        margin-right: 26px;
        border-radius: 50%;

        img {
          /*! criticalCss */
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content-box {
        /*! criticalCss */
        .name {
          /*! criticalCss */
          font-weight: 600;
          font-size: 16px;
          letter-spacing: 0.375px;
          color: #040404;
        }

        .position {
          /*! criticalCss */
          font-size: 15px;
          letter-spacing: 0.375px;
          color: rgba(4, 4, 4, 0.5);
        }

        .phone-number {
          /*! criticalCss */
          font-weight: 500;
          font-size: 15px;
          letter-spacing: 0.257143px;
          color: #040404;
          position: relative;
          transition: 0.3s;

          &:before {
            /*! criticalCss */
            content: "\e90d";
            font-family: 'icomoon';
            color: #000;
            margin-right: 6px;
            transition: 0.3s;
          }

          &:hover {
            text-decoration: none;
            color: #6fc0bc;

            &:before {
              color: #6fc0bc;
            }
          }
        }
      }
    }

    @include el('actions') {
      /*! criticalCss */
      text-align: center;

      .button {
        /*! criticalCss */
        margin-bottom: 11px;
      }

      .button-note {
        /*! criticalCss */
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        letter-spacing: -0.350503px;
        color: #000000;
      }
    }
  }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
  .welcome-section {

    .col-order {
      order: 1;
    }

    h1 {
      font-size: 19px;
    }

    .row-vertical-center {
      align-items: center;
    }

    .col-width-calculate {
      width: calc(345px - 15px) !important;
      margin-left: auto;
      padding-right: 6.4vw;
    }

    .welcome-section__image {
      &.video-container {
        .video-button {
          left: 0;
        }
      }
    }

    .btn-note {
      position: relative;
      padding-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #000;

      &:before {
        display: inline-block;
        content: "\e90a";
        font-family: 'icomoon';
        font-size: 78px;
        color: #6FC0BC;
        margin-left: -15px;
        line-height: 1;
        transform: rotate(-25.39deg);
      }

      .btn-note__txt {
        display: inline-flex;
        align-items: center;
        margin-left: 7px;
        position: relative;
        bottom: 4px;

        .icon-phone {
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .benefits {
    h3 {
      text-align: left;
    }
  }

  .process {

    padding-right: 35px;

    .name {
    }

    p {
      max-width: 80%;
    }

    .process-steps {

      padding-left: 103px;

      @include el('item') {

        &:before {

        }

        &:last-child {

          &:before {

          }
        }

        .step_icon {

          .icon {

            &:first-child {

            }

            &.icon-cube-2 {

            }
          }
        }

        .step_content {

          .title {

          }

          .txt {

          }
        }
      }

      @include mod('short') {

        .period {

          &:nth-child(2) {

            .process-steps__item {
              padding: 145px 0;
            }
          }

          &:nth-child(3) {

            .process-steps__item {
              padding: 65px 0;

              &:before {
                bottom: auto;
                height: 65px;
              }
            }
          }
        }
      }
    }
  }

  .contact-box {

    .header {
      text-align: center;
    }

    .contact-box__icons {
      justify-content: center;
    }

    @include el('socials') {

      h2:after {
        left: 50%;
        transform: translateX(-50%);
      }

    }

    @include el('person') {
      justify-content: center;
    }
  }
}

//lg: 992px
@include media-breakpoint-up(lg) {

  .welcome-section {

    h1 {
      font-size: 25px;
    }

    .col-width-calculate {
      width: calc(456px - 15px) !important;
    }

  }

  .contact-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .contact-box__icons {
      margin-bottom: 0;
      justify-content: flex-start;
    }

    .contact-box__socials {

      h2:after {
        left: 0;
        transform: translateX(0);
      }
    }
  }

  .contact-box__person {
    margin-bottom: 0;
  }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

  .welcome-section {

    margin-bottom: 80px;

    h1 {
      font-size: 40px;
    }

    .col-width-calculate {
      width: calc(605px - 15px) !important;
    }

  }

  .bg-section {
    background: url('../img/bg-top.svg') 18% 0 no-repeat;
    position: relative;
    z-index: 1;

    &:before {
      display: block;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background: url('../img/bg-top-3.svg') 0 100% no-repeat;
      z-index: -1;
    }

    &:after {
      display: block;
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background: url('../img/bg-top-2.svg') 100% 100% no-repeat;
      z-index: -1;
    }
  }

  .contact-box {
    padding-top: 70px;
    padding-bottom: 77px;
    padding-right: 91px;
    padding-left: 68px;
  }

  .blog {
    margin-bottom: 110px;
    position: relative;
    background: none;
    z-index: 1;
    padding-left: calc((100vw - 1210px - 45px) / 2);

    .container {
      max-width: none;
    }

    .nav-arrows {
      display: block;
      position: absolute;
      right: 35.5vw;
      bottom: 45px;
      transform: translateX(50%);
    }

    &:before {
      display: block;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: url('../img/bg-blog.jpg') 50% 0/cover no-repeat;
      z-index: -1;
    }

    .button {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%);
    }
  }

  .partners {
    margin-bottom: 75px;

    .partners-inner {

      .item {
        margin-right: 120px;
        height: 91px;
      }
    }
  }

  .achievements {
    padding-top: 115px;
    margin-bottom: 103px;

    .container {
      max-width: none;
      padding-left: calc((100vw - 1210px - 45px) / 2);
    }
  }

  .partners {

    .container {
      max-width: none;
      padding-left: calc((100vw - 1210px - 45px) / 2);
    }
  }

  .clients {

    margin-bottom: 89px;

    .container {
      max-width: none;
      padding-left: calc((100vw - 1210px - 45px) / 2);
    }

    .center {
      text-align: center;
    }

    .nav-box {

      .nav-arrows {
        margin-top: 12px;
      }
    }
  }

  .testimonials {
    padding-bottom: 48px;

    .testimonials-slider {
      padding-top: 10px;
    }
  }

  .slider-container {
    display: flex;

    .nav-box {
      margin-right: 131px;
      flex-shrink: 0;
    }
  }

  .process {

    .process-steps {

      @include el('item') {

        .step_content {

          .txt {
            font-size: 20px;
          }
        }

      }

      @include mod('short') {

        .process-steps__item {
          .step_content {
            .txt {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

//sl: 1440px
@include media-breakpoint-up(sl) {

}

@media (max-width: 400px) {
  .process .process-steps .btn {
    /*! criticalCss */
    padding: 10px;
    font-size: 12px;
  }

  .process .process-steps .period:after {
    /*! criticalCss */
    left: -20px;
  }

  .process .process-steps .period:before {
    /*! criticalCss */
    left: -75px;
  }

  .process .process-steps {
    /*! criticalCss */
    padding-left: 31px;
  }
}