//breakpoint 0
@include media-breakpoint-up(xs) {
    .search-engine-mobile {
        /*! criticalCss */
        display: block;

        .search-engine-action {
            /*! criticalCss */
            max-width: 290px;
            margin: 0 auto;
        }
    }

    .search-engine-mobile {
        margin-bottom: 20px;
    }

    .search-engine-desktop {
        display: none;
    }

    .search-engine-action {
        margin-top: 20px;
        position: relative;
        width: calc(100% - 25px);

        input {
            border: none;
            width: 100%;
            font-size: 13px;
            line-height: 20px;
            padding: 14px 14px 14px 40px;
            outline: none;
            border: 1px solid #E5E5E5;

            &:focus {
                border-color: #6fc0bc;
            }
        }

        .icon {
            position: absolute;
            left: 14px;
            top: 50%;
            transform: translateY(-50%);

            &:before {
                color: #ccc;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {
    .search-engine-mobile {
        display: none;
    }
    .search-engine-desktop {
        display: block;
    }

    body.search-opened {
        padding-top: 182px !important;
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}