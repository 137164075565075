//breakpoint 0
@include media-breakpoint-up(xs) {
    .count-box-extend {
        /*! criticalCss */
        background: #FFFFFF;
        box-shadow: 0px 1.71032px 20px rgba(0, 0, 0, 0.05);
        margin-bottom: 18px;
        padding: 30px 30px;
        min-height: 183px;
        position: relative;

        &:after {
            /*! criticalCss */
            display: block;
            content: "\e916";
            font-family: 'icomoon';
            position: absolute;
            top: 16px;
            right: 21px;
            font-size: 18px;
            line-height: 1;
            color: #6FC0BC;
        }

        @include el('num') {
            /*! criticalCss */
            font-weight: 700;
            font-size: 50px;
            line-height: 1;
            text-align: center;
            letter-spacing: -1.24679px;
            color: #1C263D;
            margin-bottom: 22px;
        }

        @include el('txt') {
            /*! criticalCss */
            font-size: 15px;
            line-height: 1.53;
            text-align: center;
            letter-spacing: 0.196166px;
            color: #272727;
        }

    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {
    .count-box-extend {
        max-width: auto;
        margin: 0 13px 84px;
    }

    .about_us {

        .row {
            justify-content: center;
            margin: 0 -13px;
        }

        .button {
            margin-bottom: 49px;
        }

    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}