//breakpoint 0
@include media-breakpoint-up(xs) {
    .clients-slider {
        /*! criticalCss */
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 49px;
        margin-left: -15px;
        margin-right: -15px;

        .item {
            /*! criticalCss */
            display: inline-flex !important;
            align-items: center;
            padding-right: 45px;
            margin-left: 15px;
            height: 36px;

            img {
                /*! criticalCss */
                max-height: 100%;
            }
        }

        .slick-track {
            /*! criticalCss */
            display: flex;
            align-items: center;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .clients-slider {
        margin-top: -15px;

        .item {
            height: 92px;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}

@media (max-width: 1270px) {
    .clients-slider {

        .item {

            img {
                /*! criticalCss */
                max-height: 100% !important;
            }

        }
    
    }
}