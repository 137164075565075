//breakpoint 0
@include media-breakpoint-up(xs) {

    .typ-subheader{
        margin-bottom: 0 !important;
    }

    .form {

        label {
            font-size: 14px;
            line-height: 1.57;
            display: flex;
            align-items: center;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .form-control {
            border: 1px solid #E5E5E5;
            margin-bottom: 38px;
            padding: 15px;
            border-radius: 0;
            font-size: 14px;
        }

        .checkbox-ctn {
            margin-bottom: 21px;
            text-transform: inherit;
            align-items: flex-start;
            cursor: pointer;
            flex-wrap: wrap;

            .inner{
                display:flex;
            }

            #agree1-error{
                order: 4;
                width: 100%;
                color:red;
            }

            input {
                visibility: hidden;
                height: 0;
                width: 0;
                display: none;
                margin-bottom: 0;
            }

            .state {
                display: block;
                width: 20px;
                height: 20px;
                border: 1px solid #E5E5E5;
                margin-right: 18px;
                flex-shrink: 0;
            }

            input:checked ~ div.inner .state {
                border-color: #ECF9FA;
                background: #ECF9FA url('../img/icons/tick-2.svg') 50% 50% no-repeat;
            }

            .txt {
                overflow: hidden;
                font-size: 13px;
            }
        }

        p {
            font-size: 14px;
            line-height: 1.57;
        }

        .list li {
            font-size: 14px;
            line-height: 1.57;
        }

        @include el('row'){
            .form-control{
                margin-bottom: 0;
            }
            /*! criticalCss */
            margin-bottom: 38px;
            
            div.error{
                color:red;
            }

        }

    }

    .agrees{
        *{
            font-size: 13px !important;
        }
        p:first-child{
            margin-top:0;
        }
    }

}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .form {

        p {
            font-size: 15px;
        }

        .list li {
            font-size: 15px;
        }

        

    }
}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}