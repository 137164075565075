//breakpoint 0
@include media-breakpoint-up(xs) { 
    .testimonial {
        /*! criticalCss */
        margin-top: 64px;

        @include el('logo') {
            /*! criticalCss */
            display: block;
            margin: 0 auto 52px;
            height: 32px !important;
            width: auto !important;
        }

        @include el('quote') {
            /*! criticalCss */
            font-weight: 500;
            font-style: italic;
            font-size: 18px;
            line-height: 2;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 39px;

            &:before {
                /*! criticalCss */
                content: '"';
                font-style: italic;
            }

            &:after {
                /*! criticalCss */
                content: '"';
                font-style: italic;
            }
        }

        @include el('person') {
            /*! criticalCss */
            .image {
                /*! criticalCss */
                display: block;
                width: 53px;
                height: 53px;
                margin: 0 auto;
                overflow: hidden;
                border-radius: 50%;
                margin-bottom: 17px;

                img {
                    /*! criticalCss */
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .name {
                /*! criticalCss */
                font-size: 19px;
                font-family: 'aileronregular';
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                color: #Fff;
                margin-bottom: 16px;
            }

            .position {
                /*! criticalCss */
                font-family: 'aileronregular';
                font-size: 14px;
                line-height: 1.21;
                text-align: center;
                color: #FFFFFF;
                margin-bottom: 62px;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {
    .testimonial {
        padding: 0 12vw;
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .testimonial {

        @include el('logo') {
            height: 62px !important;
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}