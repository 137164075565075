//breakpoint 0
@include media-breakpoint-up(xs) {
    #page-footer {
        /*! criticalCss */
        padding-top: 146px;
        text-align: center;
        background: url('../img/bg-footer.jpg') 50% 0/cover no-repeat;
        position: relative;

        .icon-desktop {
            /*! criticalCss */
            display: none;
        }

        .logo {
            /*! criticalCss */
            margin-bottom: 56px;
        }

        .footer-links {
            /*! criticalCss */
            margin-bottom: 55px;

            h5 {
                /*! criticalCss */
                font-weight: 600;
                font-size: 16px;
                line-height: 1.5;
                text-align: center;
                letter-spacing: -0.0772806px;
                color: #FFFFFF;
                margin-bottom: 15px;
            }

            .txt {
                /*! criticalCss */
                font-size: 14px;
                line-height: 1.7;
                text-align: center;
                color: #FFFFFF;
            }

            .menu {
                /*! criticalCss */
                list-style: none;
                padding-left: 0;

                li {
                    /*! criticalCss */
                    margin-bottom: 5px;
                    a {
                        /*! criticalCss */
                        font-size: 14px;
                        line-height: 1.7;
                        text-align: center;
                        color: #FFFFFF;
                        transition: 0.3s;

                        &:hover {
                            color: #6fc0bc;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .page-footer-foot {
            /*! criticalCss */
            border-top: 1px solid #D8D8D8;
            padding-top: 20px;
            padding-bottom: 22px;
            font-size: 14px;
            line-height: 1.5;
            color: #FFFFFF;

            a {
                color: #fff;
                font-weight: 500;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    #page-footer {

        text-align: left;

        .footer-links {
            h5,
            .txt,
            .menu li a {
                text-align: left;
            }
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {
    #page-footer {

        .icon-desktop {
            /*! criticalCss */
            display: block;
            position: absolute;

            &.icon-desktop-1 {
                /*! criticalCss */
                left: 50%;
                top: 2vw;
                font-size: 115px;
                transform: translateX(29.4vw);
            }

            &.icon-desktop-2 {
                /*! criticalCss */
                left: 50%;
                top: 6vw;
                font-size: 30px;
                transform: translateX(100%);
            }

            &.icon-desktop-3 {
                /*! criticalCss */
                left: 50%;
                top: 14vw;
                font-size: 30px;
                transform: translateX(13.4vw);
            }

            &.icon-desktop-4 {
                /*! criticalCss */
                left: 50%;
                top: 18vw;
                font-size: 30px;
                transform: translateX(-16.6vw);
                opacity: 0.5;
            }
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    #page-footer {
        padding-top: 213px;
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}